import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {meditripAPI} from "./provider/meditripAPI";

export function confirmPresenceAPI(
    accessSecret: string,
    available: boolean

): Observable<any> {
    let payload = {
        accessSecret: accessSecret,
        available: available
    };
    return meditripAPI.post(
        `online_consultation_participants/set_availability`,
        payload,
        new RestQueryParams(),
        // {
        //     Authorization: `Bearer ${accessToken}`,
        // },
    );
}
