import React from 'react';
import styles from "./styles.module.scss";
import {Switch, Route, withRouter, RouteComponentProps} from 'react-router-dom';
import Meeting from "../Meeting";
import Maintenance from "../Maintenance";
import Navigation, {INavMenu, NavigationItems} from "../Navigation";
import Settings from "../Settings";
import Chat from "../Chat";
// import Counter from "./Counter";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import { changeIsChatOpen } from '../../store/reducers/chatSlice';

interface IConnectedPanelHostProps {
    changeIsChatOpen: typeof changeIsChatOpen;
}
interface IExternalPanelHostProps {}

interface IPanelHostProps extends RouteComponentProps,
    IConnectedPanelHostProps,
    IExternalPanelHostProps {}

interface IPanelHostState {
    isChatOpen: boolean;
    panelTitle: string | null;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private navMenu: INavMenu[];

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isChatOpen: false,
            panelTitle: null
        };

        this.navMenu = [
            {
                url: '/dashboard/video',
                icon: 'iconVideo',
                title: 'video',
                onClick: this.redirect
            },
            // {
            //   url: '/dashboard/chat',
            //   icon: 'iconChat',
            //   title: 'chat',
            //   // isDisabled: true,
            //   // tooltipText: 'Coming soon',
            //   onClick: this.openChat
            // },
            // {
            //   url: '/dashboard/files',
            //   icon: 'iconFiles',
            //   title: 'files'
            // },
            {
                url: '/dashboard/settings',
                icon: 'iconSettings',
                title: 'settings',
                onClick: this.redirect
            },
            {
                url: '/dashboard/help',
                icon: 'iconHelp',
                title: 'help',
                isDisabled: true,
                tooltipText: 'Coming soon'
            }
        ];
    }

    componentDidMount() {
        this.renderTitle(this.props.location.pathname);
    }

    componentDidUpdate(
        prevProps: IPanelHostProps,
        prevState: IPanelHostState
    ) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.renderTitle(this.props.location.pathname);
        }
    }

    render() {
        return(
            <div className={`${styles.hostWrapper}`}>
                <div className={styles.navbar}>
                    <div className={styles.logoContainer}>
                        <img src={require("./../../assets/images/logo_sm.png")} alt="download"/>
                    </div>
                    <Navigation navMenu={this.navMenu}/>
                </div>
                <div className={`${styles.mainContainer}`}>
                    <div className={styles.header}>
                        <div className={styles.title}>{this.state.panelTitle}</div>
                        {/*<div>*/}
                        {/*  <Counter />*/}
                        {/*</div>*/}
                        {/*<div>Login</div>*/}
                    </div>
                    <div className={styles.content}>
                        <div className={styles.routeContent}>
                            <Switch>
                                <Route path="/dashboard/video" component={Meeting} exact strict key="meeting"/>
                                {/*<Route path="/dashboard/chat" component={Chat} exact strict key="chat"/>*/}
                                <Route path="/dashboard/settings" component={Settings} exact strict key="settings"/>
                                <Route key="not-found" component={Maintenance} />
                            </Switch>
                        </div>

                        <div className={`${styles.chat} ${!this.state.isChatOpen ? styles.closed : ''}`}>
                            <Chat />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private openChat = () => {
        this.setState({isChatOpen: !this.state.isChatOpen});
        this.props.changeIsChatOpen(!this.state.isChatOpen);
    };

    private redirect = (e: any, item: any) => {
        this.props.history.push(item.url);
        if (this.state.isChatOpen) {
            this.setState({isChatOpen: !this.state.isChatOpen});
            this.props.changeIsChatOpen(!this.state.isChatOpen);
        }
    };

    private renderTitle(path: string) {
        let title: string;
        let routePath = path.substr(path.lastIndexOf('/') + 1);

        switch (routePath) {
            case NavigationItems.VIDEO:
                title = 'Your online consultation';
                return this.setState({panelTitle: title});

            case NavigationItems.CHAT:
                title = 'Your online chat with doctor';
                return this.setState({panelTitle: title});

            case NavigationItems.SETTINGS:
                title = 'Configure your device';
                return this.setState({panelTitle: title});

            case NavigationItems.FILES:
                title = 'Useful files';
                return this.setState({panelTitle: title});

            case NavigationItems.HELP:
                title = 'Help';
                return this.setState({panelTitle: title});
        }

        return this.setState({panelTitle: null});
    }
}

export default connect(
    (state: RootState) => ({}),
    {
        changeIsChatOpen
    }
)(withRouter(PanelHost));
