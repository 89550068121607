import React from 'react';
import styles from "./styles.module.scss";

interface ICallExpiredProps {}

interface ICallExpiredState {}

class CallExpired extends React.Component<ICallExpiredProps, ICallExpiredState> {

    render() {
        return (
            <div className={`${styles.host}`}>
                <div className={styles.header}>
                    <h1>The meeting is already over</h1>
                </div>
                <div className={styles.information}>
                    In case you missed the meeting, please contact admin or visit our webpage in order to make new appointment.
                </div>
            </div>
        );
    }
}

export default CallExpired;
