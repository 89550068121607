import {meditripAPI} from "./provider/meditripAPI";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";

export function setConsultationDurationAPI(
    secret: string,
    consultationId: string,
    durationInSecond: number,
    durationStartAt?: string,
    durationEndsAt?: string
): Observable<any> {
    let payload = {
        durationStartsAt: durationStartAt ? durationStartAt : null,
        durationEndsAt: durationEndsAt ? durationEndsAt : null,
        durationInSecond: durationInSecond
    };

    return meditripAPI.put(
        `online_consultation/${consultationId}/finish`,
        payload,
        new RestQueryParams(),
         {
            'X-Online-Consultation-Secret': secret
         }
    );
}
