import React from 'react';
import styles from "./styles.module.scss";

interface ICallNotFoundProps {}

interface ICallNotFoundState {}

class CallNotFound extends React.Component<ICallNotFoundProps, ICallNotFoundState> {

    render() {
        return (
            <div className={`${styles.host}`}>
                <div className={styles.header}>
                    <img src={require(`./../../assets/images/logo_sm.png`)} alt="logo" className={styles.logo}/>

                    <h1>You are not allowed to join this consultation.</h1>
                </div>
                <div className={styles.information}>
                    <p>Check your URL link or contact your Clinic.</p>
                </div>
            </div>
        );
    }
}

export default CallNotFound;
